define('ember-cli-jsoneditor/components/json-editor', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  var merge = _ember['default'].merge;
  var isNone = _ember['default'].isNone;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var defineProperty = _ember['default'].defineProperty;

  var possibleOptions = ['ace', 'ajv', 'escapeUnicode', 'history', 'modes', 'search', 'indentation', 'theme', 'disabled'];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ember-cli-jsoneditor'],

    name: 'JSONEditor',
    mode: 'tree',
    history: true,
    search: true,
    indentation: 2,
    escapeUnicode: false,
    theme: 'ace/theme/jsoneditor',
    modes: ['tree', 'view', 'form', 'text', 'code'],

    disabled: false,

    onChange: function onChange() {},
    onError: function onError() {},
    onModeChange: function onModeChange() {},
    onEditable: function onEditable(e) {
      return e;
    },

    init: function init() {
      this._super.apply(this, arguments);
      defineProperty(this, 'options', computed.apply(undefined, possibleOptions.concat([this.getOptions])));
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._createEditorPid = run.scheduleOnce('afterRender', this, this.createEditor);
    },

    destroy: function destroy() {
      this._super.apply(this, arguments);

      var editor = this.get('editor');

      if (!isNone(editor)) {
        editor.destroy();
      }

      run.cancel(this._createEditorPid);
    },

    createEditor: observer('options', function () {
      if (!this.notDestroyed()) {
        return;
      }

      var element = this.get('element');
      var editor = this.get('editor');

      if (isNone(element)) {
        return;
      }

      if (!isNone(editor)) {
        editor.destroy();
      }

      this.set('editor', new JSONEditor(element, this.get('options'), this.getJSON()));
    }),

    modeChanged: observer('mode', function () {
      if (this.notDestroyed()) {
        this.get('editor').setMode(this.get('mode'));
      }
    }),

    nameChanged: observer('name', function () {
      if (this.notDestroyed()) {
        this.get('editor').setName(this.get('name'));
      }
    }),

    schemaChanged: observer('schema', function () {
      if (this.notDestroyed()) {
        this.get('editor').setSchema(this.get('schema'));
      }
    }),

    jsonChanged: observer('json', function () {
      // Only update json if it was change programatically
      if (!this._isTyping && this.notDestroyed()) {
        this.get('editor').set(this.getJSON());
      }
    }),

    getOptions: function getOptions() {
      var _this = this;

      var options = this.getProperties(possibleOptions);
      merge(options, this.getProperties(['name', 'mode', 'schema']));

      if (options.disabled) {
        options.mode = 'view';
        options.modes = ['view'];
      }

      options.onChange = function () {
        _this._isTyping = true;
        var editor = _this.get('editor');
        try {
          _this.get('onChange')(editor.get());
        } catch (err) {
          if (isEmpty(editor.getText())) {
            _this.get('onChange')({});
          }
        }
        _this._isTyping = false;
      };

      options.onError = this.get('onError');
      options.onModeChange = this.get('onModeChange');
      options.onEditable = this.get('onEditable');

      delete options.disabled;

      return options;
    },

    getJSON: function getJSON() {
      var json = this.get('json');
      if (typeof json === "string") {
        return JSON.parse(json);
      }
      return json;
    },

    notDestroyed: function notDestroyed() {
      return !this.get('isDestroyed') && !this.get('isDestroyed');
    }
  });
});
/* global JSONEditor */