define('ember-c3/components/c3-chart', ['exports', 'ember-component', 'ember-metal/get', 'ember-metal/set', 'ember-runloop', 'c3'], function (exports, _emberComponent, _emberMetalGet, _emberMetalSet, _emberRunloop, _c3) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: 'div',
    classNames: ['c3-chart-component'],

    // triggered when data is updated by didUpdateAttrs
    _reload: function _reload() {
      var chart = (0, _emberMetalGet['default'])(this, 'c3chart');

      // if data should not be appended
      // e.g. when using a pie or donut chart
      if ((0, _emberMetalGet['default'])(this, 'unloadDataBeforeChange')) {
        chart.unload();
        // default animation is 350ms
        // t/f data must by loaded after unload animation (400)
        // or chart will not properly render
        (0, _emberRunloop.later)(this, function () {
          chart.load(
          // data, axis, color are only mutable elements
          (0, _emberMetalGet['default'])(this, 'data'), (0, _emberMetalGet['default'])(this, 'axis'), (0, _emberMetalGet['default'])(this, 'color'));
        }, 400);
      } else {
        chart.load((0, _emberMetalGet['default'])(this, 'data'), (0, _emberMetalGet['default'])(this, 'axis'), (0, _emberMetalGet['default'])(this, 'color'));
      }
    },

    // triggered when component added by didInsertElement
    _setupc3: function _setupc3() {
      // get all base c3 properties
      var chartConfig = (0, _emberMetalGet.getProperties)(this, ['data', 'axis', 'regions', 'bar', 'pie', 'donut', 'gauge', 'grid', 'legend', 'tooltip', 'subchart', 'zoom', 'point', 'line', 'area', 'size', 'padding', 'color', 'transition']);

      // bind c3 chart to component's DOM element
      chartConfig.bindto = (0, _emberMetalGet['default'])(this, 'element');

      // emit events to controller
      callbacks.call(this);
      function callbacks() {
        var that = this;
        var c3events = ['oninit', 'onrendered', 'onmouseover', 'onmouseout', 'onresize', 'onresized'];
        c3events.forEach(function (event) {
          chartConfig[event] = function () {
            that.sendAction(event, that);
          };
        });
      }

      // render the initial chart
      (0, _emberMetalSet['default'])(this, 'c3chart', _c3['default'].generate(chartConfig));
    },

    /***
     * Component lifecycle hooks to control rendering actions
     ***/

    didReceiveAttrs: function didReceiveAttrs() {
      // if DOM is not ready when component is inserted,
      // rendering issues can occur
      // t/f use 'afterRender' property to ensure
      // state readiness
      try {
        (0, _emberRunloop.scheduleOnce)('afterRender', this, this._setupc3);
      } catch (err) {
        console.log(err);
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      // if data proprety is dependent on async relationships,
      // animations can cause buggy renders, therefore debounce
      // component update to ensure proper visualization
      (0, _emberRunloop.debounce)(this, this._reload, 360);
    },

    willDestroyElement: function willDestroyElement() {
      // execute teardown method
      this._super();
      (0, _emberMetalGet['default'])(this, 'c3chart').destroy();
    }
  });
});