define('ember-swagger-ui/components/swagger-ui', ['exports', 'swagger-ui'], function (exports, _swaggerUi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SwaggerUIBundle = _swaggerUi.default.SwaggerUIBundle;
  exports.default = Ember.Component.extend({
    classNames: ['swagger-ui', 'component-swagger-ui'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var config = this.get('config') || { deepLinking: false };
      var merged = Object.assign({}, config);
      if (!merged.dom_id) {
        merged.dom_id = '#' + this.get('elementId');
      }

      SwaggerUIBundle(merged);
    }
  });
});