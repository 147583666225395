define('ember-cli-data-export/components/export-selector-onselect', ['exports', 'ember', 'ember-cli-data-export/templates/components/export-selector-onselect'], function (exports, _ember, _emberCliDataExportTemplatesComponentsExportSelectorOnselect) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliDataExportTemplatesComponentsExportSelectorOnselect['default'],
    selectPrompt: 'Export File',

    actions: {
      triggerExport: function triggerExport(selectedType) {
        this.sendAction('exportData', selectedType);
        this.$('#exportTypes').val('');
      }
    }
  });
});